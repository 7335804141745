import React, { Fragment } from "react";
import {
  Box,
  Link,
  Typography,
} from "@mui/material";
import EmbedComponent from "../../../components/Ninety-Nine/EmbedComponent";
import NinetyNineHeroSection from "./components/NinetyNineHeroSection";
import CustomBreadcrumbs from "./components/CustomBreadcrumbs";

const NinetyNineStateOfTheNation = () => {
  const title = "Economic activity and social change in the UK, real-time indicators"
  const subtext= `Early data on the UK economy and society. These faster indicators are
            created using rapid response surveys, novel data sources and innovative methods.
            These are official statistics in development.`

  return (
    <Fragment>
      <NinetyNineHeroSection title={title} subtext={subtext}/>

        <Box
        sx={{
          width: '70%',
          margin: '0 auto',
           mb: 4
        }}>
          <CustomBreadcrumbs />
  <Link href="https://www.ons.gov.uk/economy/economicoutputandproductivity/output/bulletins/economicactivityandsocialchangeintheukrealtimeindicators/latest#cite-this-statistical-bulletin" target="_blank" rel="noopener">
      <Typography variant="body1" component="div" sx={{paddingBottom: 1}} >
        Source: ONS
      </Typography>
    </Link>
        <EmbedComponent
          url='https://www.ons.gov.uk/visualisations/dvc3030/dashboard/index.html'
          width="100%"
          height="95vh"
        />
 </Box>
    </Fragment>
  );
};

export default NinetyNineStateOfTheNation;