import React, { Fragment, useState } from "react";
import {Link } from "react-router-dom";
import { Chip, Container, Box, Stack } from "@mui/material";
import NinetyNineHeroSection from "./components/NinetyNineHeroSection";
import NinetyNineChartOfTheWeek from "./NinetyNineChartOfTheWeek";
import image from "../../../assets/images/G7-GDP-per-capita.png";
import CustomBreadcrumbs from "./components/CustomBreadcrumbs";

const NinetyNineHomeDashboard = () => {
  const title = "99% DataHub";
  const subtext = `A destination for comprehensive economic data and insights. We aggregate the latest reports,
  statistics, and news from leading think tanks, research institutes, and supranational organizations like the IMF,
   World Bank, and OECD. Whether you're an economist, researcher, or just someone looking to stay informed,
   The DataHub will provide a centralized platform where you can access real-time updates,
   in-depth analyses, and reliable data—all in one place.`;

  const handleClick = (url) => {
    window.open(url, "_blank");
  };


  // Render the main component if data is loaded and no error
  return (
    <Fragment>
      <NinetyNineHeroSection title={title} subtext={subtext} />
      <Container>
        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
          }}
        >
          {/* Chips Section */}
          <Stack direction="row" spacing={2}>
          <Chip label="News" component={Link} to="/ninety-nine/news" clickable />
            <Chip
              label="UK Economics Dashboard"
              component="a"
              href="/ninety-nine/uk-econ"
              clickable
            />
            <Chip
              label="OECD Economic Wellbeing Dashboard"
              component="a"
              href="/ninety-nine/oecd-economic-wellbeing"
              clickable
            />
            <Chip
              label="OECD Housing"
              component="a"
              href="/ninety-nine/oecd-housing"
              clickable
            />
            <Chip
              label="OECD Governments At A Glance"
              onClick={() =>
                handleClick(
                  "https://www.oecd.org/en/data/dashboards/government-at-a-glance-2023.html"
                )
              }
              clickable
            />
            <Chip
              label="State of the Nation"
              component="a"
              href="/ninety-nine/state-of-the-nation"
              clickable
            />
          </Stack>

          <NinetyNineChartOfTheWeek />
        </Box>
      </Container>
    </Fragment>
  );
};

export default NinetyNineHomeDashboard;
