// src/components/NotFound.jsx
import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

const NotFound = () => {
  const location = useLocation();

  useEffect(() => {
    console.error(`No route matched for URL: ${location.pathname}`);
    // Optionally, send this information to an analytics service
  }, [location]);

  return (
    <div style={styles.container}>
      <h1>404 - Page Not Found</h1>
      <p>The page you’re looking for doesn’t exist.</p>
      <Link to="/" style={styles.link}>
        Go to Home
      </Link>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
  },
  link: {
    color: '#3498db',
    textDecoration: 'none',
  },
};

export default NotFound;

