import React, { useState, useCallback } from "react";
import { useSpring, animated } from "@react-spring/web";
import {
  Box,
  Typography,
  Avatar,
  Link,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import SkillChips from "../SageQuery/Skills";
import AvatarImageHoC from "../../assets/images/Hoc.png";
import AvatarImage99 from "../../assets/images/99pct.jpg";
import AvatarImageArdent from "../../assets/images/ardentAvatar.png";
import AvatarImageInvestments from "../../assets/images/investments.jpg";
import AvatarImageMe from "../../assets/images/sketch_avatar.jpg";
import ResponseModal from "../../components/Login/ResponseModal";
import Login from "../../components/Login/Login";
import { useFetchCredentialsQuery } from "../../components/Login/LoginApi";
import { useGetPublicProjectsQuery } from "./HomePageApi";
import PublicAvatarBadge from "./components/HomePage/PublicAvatarBadge";

const AnimatedBox = styled(animated.div)({
  textAlign: "center",
  zIndex: 1,
  width: "100%",
});

const TechBackground = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background:
    "url(https://www.transparenttextures.com/patterns/asfalt-light.png), linear-gradient(135deg, #1f1c2c 0%, #928dab 100%)",
  animation: "backgroundAnimation 10s ease-in-out infinite",
  "@keyframes backgroundAnimation": {
    "0%": { backgroundPosition: "0% 50%" },
    "50%": { backgroundPosition: "100% 50%" },
    "100%": { backgroundPosition: "0% 50%" },
  },
});

const ScrollingContainer = styled(Box)({
  width: "100%",
  background: "transparent",
  padding: "10px 0",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
});

const avatarsData = [
  {
    alt: "99%",
    src: AvatarImage99,
    page: "ninety-nine",
    description:
      "Built using React and Django, this WIP project contains dashboards linked to the House of Commons and OECD as well as news displayed from various national and international sources via API and scraped with BeautifulSoup. Richer dashboards, data sources and news search/tagging to be added.",
  },
  // {
  //   alt: "HoC",
  //   src: AvatarImageHoC,
  //   page: "my-mp",
  //   description:
  //     "Using React, this public project fetches data using the UK Parliament API. It constructs a summary using the latest Register of Members Interests.",
  // },
  {
    alt: "Ardent",
    src: AvatarImageArdent,
    page: "ardent",
    description:
      "Built using React, Django and PostgreSQL, this project was built for a small financial brokerage and is composed of a series of integrated components including a CRM interface, trading blotter, order management system as well as some tools to assist the research function such as programmatic EDGAR and SEC access.",
  },
  {
    alt: "ETFs",
    src: AvatarImageInvestments,
    page: "investment",
    description:
      "Built using React, Django and PostgreSQL, this is the project that I use for managing my personal investments. It fetches every ETF and IT traded on the LSE along with weekly and monthly volumes and joins it to my algorithmic models from TradingView to give me a comprehensive view of >4000 possible investment opportunities globally.",
  },
];

const HomePage = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { data, error } = useFetchCredentialsQuery();
  const { data: publicProjects, error: ErrorPublicProjects } =
    useGetPublicProjectsQuery("core");
  const [zoomed, setZoomed] = useState(false);
  const [hoveredAvatar, setHoveredAvatar] = useState(null);

  const publicProjectNames = publicProjects
    ? publicProjects.map((project) => project.name)
    : [];
  console.log("data: ", data);
  const handleAvatarClick = (page) => {
    if (publicProjectNames.includes(page)) {
      navigate(`/${page}`);
    } else {
      if (data && data.project_access.includes(page)) {
        if (page === "ardent") {
          window.location.href = "https://ardent.vincegomez.com";
          return;
        }
        navigate(`/${page}`);
      } else {
        setModalMessage("You do not have access to this project.");
        setModalOpen(true);
      }
    }
  };

  const handleZoom = () => {
    setZoomed(!zoomed);
  };

  const props = useSpring({
    from: { opacity: 0, transform: "translate3d(0,-50px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { tension: 280, friction: 60 },
  });

  const handleAvatarHover = useCallback((avatar) => {
    setHoveredAvatar(avatar);
  }, []);

  const handleAvatarLeave = useCallback(() => {
    setHoveredAvatar(null);
  }, []);

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "hidden",
        backgroundColor: "#0e0b16",
        color: "#a239ca",
        margin: "1px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          zIndex: 2,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: "#0e0b16",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {avatarsData.map((avatar, index) => (
            <PublicAvatarBadge
              key={index}
              alt={avatar.alt}
              src={avatar.src}
              sx={{ width: "50px", height: "50px", cursor: "pointer" }}
              isPublic={publicProjectNames.includes(avatar.page)}
              onClick={() => handleAvatarClick(avatar.page)}
              onMouseEnter={() => handleAvatarHover(avatar)}
              onMouseLeave={handleAvatarLeave}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Optional: Center the text horizontally
            gap: "10px", // Optional: Add some space between the error messages
          }}
        >
          {error && (
            <Typography
              variant="h7"
              sx={{
                color: "red",
                paddingLeft: "5px",
              }}
            >
              Credentials Error:
              {error.error}
            </Typography>
          )}
          {ErrorPublicProjects && (
            <Typography
              variant="h7"
              sx={{
                color: "red",
                paddingRight: "5px",
              }}
            >
              Public Projects Error:
              {ErrorPublicProjects.error}
            </Typography>
          )}
        </Box>
        {isDesktop && <Login />}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "70px", // Space for the fixed header
          textAlign: "center",
          padding: "20px",
        }}
      >
        <AnimatedBox style={props}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Avatar
              alt="VG"
              src={AvatarImageMe}
              sx={{
                width: { xs: "80px", md: "160px" }, // Adjusted sizes for responsiveness
                height: { xs: "80px", md: "160px" },
                cursor: "pointer",
                transition: "width 0.3s ease-in-out, height 0.3s ease-in-out",
                zIndex: zoomed ? 15 : 1,
              }}
              onClick={handleZoom}
            />
            <Box
              sx={{
                marginTop: { xs: "10px", md: "50px" }, // Add spacing above the title
                marginBottom: { xs: "10px", md: "30px" }, // Add spacing below the title
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: "#e7dfdd",
                  fontSize: { xs: "1.5rem", md: "3rem" }, // Responsive font size
                }}
              >
                Vince Gomez's Developer Hub
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%", // Make the box full width
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: "800px", // Set a max width
                width: "100%", // Make the box full width
                flexDirection: "column",
                marginTop: { xs: "10px", md: "20px" }, // Add spacing above the text block
                marginBottom: { xs: "10px", md: "50px" }, // Add spacing below the text block
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "1rem", md: "1.5rem" }, // Responsive font size
                  color: "#e7dfdd",
                  marginTop: "20px",
                  maxWidth: "800px",
                }}
              >
                I'm Vince Gomez, a dedicated IT developer with a mission to turn
                complex problems into elegant solutions. From startups to
                enterprises, I partner with clients to deliver custom software
                and innovative tech solutions.
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: { xs: "10px", md: "30px" }, // Add spacing above the text block
              marginBottom: { xs: "10px", md: "50px" }, // Add spacing below the text block
            }}
          >
            <Grid item>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "1rem", md: "1.35rem" }, // Responsive font size
                  color: "#e7dfdd",
                }}
              >
                To learn about my approach and find out how we can collaborate
                to drive your business forward click
              </Typography>
            </Grid>
            <Grid item sx={{ paddingLeft: "6px" }}>
              <Link
                variant="body1"
                sx={{
                  fontSize: { xs: "1rem", md: "1.35rem" }, // Responsive font size
                  color: "#1025e3",
                  marginLeft: "4px",
                  textDecoration: "none", // Ensures no underline
                  "&:visited": {
                    color: "#1025e3", // Keeps the link color the same after it has been clicked
                  },
                  "&:hover": {
                    textDecoration: "underline", // Adds underline on hover
                  },
                  "&:active": {
                    color: "#1025e3", // Keeps the link color the same when active
                  },
                }}
                href="https://www.vincegomez.com/sagequery"
                target="_blank"
                rel="noopener"
              >
                here.
              </Link>
            </Grid>
          </Grid>
          <ScrollingContainer>
            <SkillChips animate />
          </ScrollingContainer>
        </AnimatedBox>
      </Box>
      <TechBackground />

      {hoveredAvatar && (
        <Box
          sx={{
            position: "absolute",
            top: "15%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            padding: "10px",
            borderRadius: "10px",
            zIndex: 10,
            maxWidth: "80%",
            textAlign: "center",
          }}
        >
          <Typography variant="body1">{hoveredAvatar.description}</Typography>
        </Box>
      )}

      <ResponseModal
        open={modalOpen}
        message={modalMessage}
        onClose={() => setModalOpen(false)}
      />
    </Box>
  );
};

export default HomePage;
