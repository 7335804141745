import React, { Fragment } from "react";
import { Container, Grid, Box } from '@mui/material';
import AddDashboardChart from "./AddDashboardChart";
import UpdateDashboardChart from "./UpdateDashboardChart";

import NinetyNineHeroSection from "../../PublicProjects/NinetyNine/components/NinetyNineHeroSection";

const NinetyNineAdmin = () => {
  const title = "Admin";
  const subtext = ""
  return (
    <Fragment>
       <NinetyNineHeroSection title={title} subtext={subtext}/>

      <Box
        sx={{
          width: '90%',
          margin: '0 auto',
        }}
      >
        <Grid container spacing={2}>
          {/* Form Section - 1/3 of the width */}
          <Grid item xs={12} md={4}>
            <AddDashboardChart />
          </Grid>

          {/* DataGrid Section - 2/3 of the width */}
          <Grid item xs={12} md={8}>
            <UpdateDashboardChart />
          </Grid>
        </Grid>
      </Box>

    </Fragment>
  );
};

export default NinetyNineAdmin;
