import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// The component accepts the data as a prop and builds the chart
const HighchartsLineChart = ({ data }) => {
  // Configuration for Highcharts
  const options = {
    title: {
      text: 'Country Data Over Time',
    },
    xAxis: {
      title: {
        text: 'Year',
      },
      type: 'linear',
      allowDecimals: false,
    },
    yAxis: {
      title: {
        text: 'Value',
      },
    },
    series: data.series, // Use the series data passed as props
    chart: {
      type: 'line',
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      crosshairs: true,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

const App = ({data}) => {

  return (
    <div>
      <HighchartsLineChart data={data} />
    </div>
  );
};

export default App;
