import { api } from "../../services/api";

const AdminApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createDashboardChart: builder.mutation({
      query: (dataPayload) => ({
        url: `${dataPayload.projectName}/dashboard-charts/create/`,
        method: "POST",
        body: JSON.stringify(dataPayload.data),
        headers: {
          "Content-Type": "application/json",
          "Project-Name": dataPayload.projectName,
        },
      }),
      invalidatesTags: ["99Charts"],
    }),
    getChartOfTheWeek: builder.query({
      query: (projectName) => ({
        url: `${projectName}/chart-of-the-week/`,
        headers: {
          "Project-Name": projectName,
        },
      }),
      providesTags: ["99Charts"],
    }),
    setChartOfTheWeek: builder.mutation({
      query: ({ projectName, chartId }) => ({
        // url: "chart-of-the-week/",
        url: `${projectName}/chart-of-the-week/`,
        method: "PATCH",
        body: { chart: chartId },
        headers: {
          "Project-Name": projectName,

        },
      }),
      invalidatesTags: ["99Charts"],
      providesTags: ["ChartOfTheWeek"],
    }),
    updateDashboardChart: builder.mutation({
      query: (dataPayload) => ({
        url: `${dataPayload.projectName}/update-dashboard-chart/${dataPayload.id}/`,
        method: "PUT", // Using PUT for updating the chart
        body: JSON.stringify(dataPayload.data),
        headers: {
          "Content-Type": "application/json",
          "Project-Name": dataPayload.projectName,
        },
      }),
      invalidatesTags: ["99Charts"],
    }),
    deleteDashboardChart: builder.mutation({
      query: ({ id, projectName }) => ({
        url: `${projectName}/dashboard-charts/${id}/`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Project-Name": projectName ,
        },
      }),
      invalidatesTags: ['99Charts', 'ChartOfTheWeek'], // Ensure cache consistency
    }),
    getDashboardCharts: builder.query({
      query: (projectName) => ({
        url: `${projectName}/dashboard-charts/`,
        method: "GET",
        headers: {
          "Project-Name": projectName,
        },
      }),
    }),
    providesTags: ["99Charts"],
  }),
});

export const {
  useCreateDashboardChartMutation,
  useGetDashboardChartsQuery,
  useUpdateDashboardChartMutation,
  useDeleteDashboardChartMutation,
  useGetChartOfTheWeekQuery,
  useSetChartOfTheWeekMutation,
} = AdminApi;
