import React from "react";
import { Box, Breadcrumbs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const breadcrumbNameMap = {
  "/ninety-nine/news": "News",
  "/ninety-nine/uk-econ": "UK Economics Dashboard",
  "/ninety-nine/oecd-economic-wellbeing": "OECD Economic Wellbeing Dashboard",
  "/ninety-nine/oecd-housing": "OECD Housing",
  "/ninety-nine/state-of-the-nation": "State of the Nation",
};

const CustomBreadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  console.log("Pathnames",pathnames)

  return (
    <Box
    sx={{
      margin: '0 auto',
       mb: 2
    }}>
    <Breadcrumbs aria-label="breadcrumb"
      sx={{
        fontSize: ".9rem",
        "& a": {
          fontSize: ".9rem",
          textDecoration: "none", // Remove underline
          "&:hover": {
            textDecoration: "underline", // Add underline on hover
          }
        }
      }}>
      <Link color="inherit" to="/ninety-nine">
        Home
      </Link>
      {pathnames.slice(1).map((_, index) => {
        const url = `/${pathnames.slice(0, index + 2).join("/")}`;
        return (
          <Link key={url} color="inherit" to={url}>
            {breadcrumbNameMap[url]}
          </Link>
        );
      })}
    </Breadcrumbs>
    </Box>
  );
};

export default CustomBreadcrumbs;
