 // src/components/AlertMessage.jsx
import React, { useEffect, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

const AlertMessage = ({ status_txt, message, open, onClose }) => {
  const isError = status_txt === 'error';
  const [snackbarOpen, setSnackbarOpen] = useState(open);

  useEffect(() => {
    if (open) {
      setSnackbarOpen(true);
    }
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
    if (onClose) onClose();
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={isError ? 'error' : 'success'}
        sx={{ width: '100%' }}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;


