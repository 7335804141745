import { api } from '../../../services/api'

const NinetyNineApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChartOfTheWeek: builder.query({
      query: (projectName) => ({
        url: `${projectName}/chart-of-the-week/`,
        headers: {
          "Project-Name": projectName,
        },
      }),
    }),
    get99UKEconomics: builder.query({
        query: (projectName) => `${projectName}/uk-economic-news/`,
        providesTags: ['99Economics'],
      }),
      get99UKThinkTanks: builder.query({
        query: (projectName) => `${projectName}/uk-think-tank-news/`,
        providesTags: ['99Economics'],
      }),
      get99InternationalNews: builder.query({
        query: (projectName) => `${projectName}/international-news/`,
        providesTags: ['99Economics'],
      }),
      getSDMX: builder.mutation({
        query: ({projectName,url}) => ({
          url: `${projectName}/fetch-sdmx-data/`,
          method: "POST",
          body: {url},
          headers: {
            "Content-Type": "application/json",
          },
        }),
      }),
  }),

  overrideExisting: false,
})

export const { useGetChartOfTheWeekQuery, useGet99InternationalNewsQuery, useGet99UKEconomicsQuery,
useGet99UKThinkTanksQuery, useGetSDMXMutation } = NinetyNineApi