import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Link
} from "@mui/material";
import EmbedComponent from "../../../components/Ninety-Nine/EmbedComponent";
import NinetyNineHeroSection from "./components/NinetyNineHeroSection";
import CustomBreadcrumbs from "./components/CustomBreadcrumbs";

const NinetyNineOECDHousing = () => {
  const title = "Affordable Housing: the OECD dashboard"
  const subtext= `Compare how different countries housing policies have
                shaped their housing markets  .`

  return (
    <Fragment>
      <NinetyNineHeroSection title={title} subtext={subtext}/>

        <Box
        sx={{
          width: '90%',
          margin: '0 auto',
           mb: 4
        }}>
          <CustomBreadcrumbs />
      <Link href="https://www.compareyourcountry.org/housing/en/0/all/default" target="_blank" rel="noopener">
      <Typography variant="body1" component="div" sx={{paddingBottom: 1}} >
        Source: OECD
      </Typography>
    </Link>
        <EmbedComponent
          url="//www.compareyourcountry.org/housing/en/1/all/default"
          width="100%"
          height="80vh"
        />
        </Box>

    </Fragment>
  );
};

export default NinetyNineOECDHousing;