import React, { Fragment } from "react";
import { Box, Container, Typography, Link } from "@mui/material";
import EmbedComponent from "../../../components/Ninety-Nine/EmbedComponent";
import NinetyNineHeroSection from "./components/NinetyNineHeroSection";
import CustomBreadcrumbs from "./components/CustomBreadcrumbs";

const NinetyNineUKEconDashboard = () => {
  const title = "The UK economy: a dashboard";
  const subtext = `This dashboard, taken from the House of Commons website, allows you to investigate how the UK economy has performed over
        the past 70 years
        It features the latest data and is updated every time the Office for National Statistics publishes new data.
        For each topic, links are provided to relevant Library briefings and Office for National Statistics (ONS) publications for further information.
        Data can be downloaded as spreadsheets (csv files).`;

  return (
    <Fragment>
      <NinetyNineHeroSection title={title} subtext={subtext} />
      <Container>
        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
            mb: 4,
          }}
        >
          <CustomBreadcrumbs />
          <Link
            href="https://commonslibrary.parliament.uk/the-uk-economy/"
            target="_blank"
            rel="noopener"
          >
            <Typography
              variant="body1"
              component="div"
              sx={{ paddingBottom: 1 }}
            >
              Source: House of Commons
            </Typography>
          </Link>
          <EmbedComponent
            url="https://app.powerbi.com/view?r=eyJrIjoiYjY0ZTRjZDMtYzg4Yi00ZjI3LThkYTAtZTAzZmI4ODM4YjAxIiwidCI6IjFjZTZkZDllLWIzMzctNDA4OC1iZTVlLThkYmJlYzA0YjM0YSIsImMiOjh9"
            width="100%"
            height="80vh"
          />
        </Box>
      </Container>
    </Fragment>
  );
};

export default NinetyNineUKEconDashboard;
