// src/components/EditableDeletableGrid.jsx

import React, { useMemo, useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox"; // Import MUI Checkbox
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress"; // Import for loading indicator
import {
  GridRowModes,
  DataGridPro,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid-pro";
import {
  useGetDashboardChartsQuery,
  useGetChartOfTheWeekQuery,
  useSetChartOfTheWeekMutation,
  useUpdateDashboardChartMutation,
  useDeleteDashboardChartMutation,
} from "../AdminApi"; // Adjust the import path as needed
import AlertMessage from "../../../components/Ninety-Nine/AlertMessage";

export default function EditableDeletableGrid() {
  // State for managing alert messages
  const [alertData, setAlertData] = useState(null);

  // Define your project name (replace with dynamic value if necessary)
  const projectName = "ninety-nine";

  // Fetch DashboardCharts
  const {
    data: chartsData = [],
    error: chartsError,
    isLoading: chartsLoading,
    refetch: refetchCharts,
  } = useGetDashboardChartsQuery(projectName);

  // Fetch ChartOfTheWeek
  const {
    data: cotwData,
    error: cotwError,
    isLoading: cotwLoading,
    refetch: refetchCotw,
  } = useGetChartOfTheWeekQuery(projectName);

  // Mutation to set a new ChartOfTheWeek
  const [setChartOfTheWeek, { isLoading: isSettingCotw }] = useSetChartOfTheWeekMutation();

  // State for managing the selected row's ID
  const [selectedRowId, setSelectedRowId] = useState(null);

  // Effect to set the selectedRowId based on ChartOfTheWeek
  useEffect(() => {
    if (cotwData && cotwData.chart) {
      setSelectedRowId(cotwData.chart.id);
      console.log('ChartOfTheWeek ID:', cotwData.chart.id);
    } else {
      setSelectedRowId(null);
      console.log('No ChartOfTheWeek set.');
    }
  }, [cotwData]);

  // Mutations for updating and deleting
  const [updateDashboardChart] = useUpdateDashboardChartMutation();
  const [deleteDashboardChart] = useDeleteDashboardChartMutation();

  // State for managing row modes
  const [rowModesModel, setRowModesModel] = useState({});

  // Handle stopping the row edit
  const handleRowEditStop = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  // Memoized handler to initiate edit mode
  const handleEditClick = useCallback(
    (id) => () => {
      setRowModesModel((prev) => ({
        ...prev,
        [id]: { mode: GridRowModes.Edit },
      }));
    },
    []
  );

  // Memoized handler to save the edited row
  const handleSaveClick = useCallback(
    (id) => () => {
      setRowModesModel((prev) => ({
        ...prev,
        [id]: { mode: GridRowModes.View },
      }));
    },
    []
  );

  // Memoized handler to delete a row
  const handleDeleteClick = useCallback(
    (id) => async () => {
      try {
        await deleteDashboardChart({ projectName, id }).unwrap(); // Assuming you are using RTK Query
        setSelectedRowId((prevId) => (prevId === id ? null : prevId));
        setAlertData({
          status_txt: "success",
          message: "Record deleted successfully.",
          open: true,
        });
        // Refetch data to ensure consistency
        refetchCharts();
        refetchCotw();
      } catch (err) {
        console.error("Failed to delete the record:", err);
        setAlertData({
          status_txt: "error",
          message: err?.data?.detail || "Failed to delete the record.",
          open: true,
        });
      }
    },
    [deleteDashboardChart, refetchCharts, refetchCotw]
  );

  // Memoized handler to cancel the edit mode
  const handleCancelClick = useCallback(
    (id) => () => {
      setRowModesModel((prev) => ({
        ...prev,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      }));
    },
    []
  );

  // Handler for checkbox click
  const handleCheckboxClick = useCallback(
    (id) => {
      if (id !== selectedRowId) {
        console.log('id:', id)
        // Update the ChartOfTheWeek in the backend
        setChartOfTheWeek({ projectName, chartId: id })
          .unwrap()
          .then(() => {
            setSelectedRowId(id);
            setAlertData({
              status_txt: "success",
              message: "Chart of the Week updated successfully.",
              open: true,
            });
            // Refetch data to ensure consistency
            refetchCharts();
            refetchCotw();
          })
          .catch((error) => {
            console.error("Failed to set Chart of the Week:", error);
            setAlertData({
              status_txt: "error",
              message: error?.data?.detail || "Failed to set Chart of the Week.",
              open: true,
            });
          });
      } else {
        // If the same checkbox is clicked again, do nothing or handle accordingly
        console.log(`Chart of the Week is already set to row with id: ${id}`);
      }
      console.log(`Checkbox clicked for row with id: ${id}`);
    },
    [selectedRowId, setChartOfTheWeek, projectName, refetchCharts, refetchCotw]
  );

  // Process row updates after editing
  const processRowUpdate = useCallback(
    async (newRow) => {
      try {
        await updateDashboardChart(newRow).unwrap(); // Update backend
        setAlertData({
          status_txt: "success",
          message: "Record updated successfully.",
          open: true,
        });
        // Refetch data to ensure consistency
        refetchCharts();
        refetchCotw();
        return newRow;
      } catch (error) {
        console.error("Failed to update the record:", error);
        setAlertData({
          status_txt: "error",
          message: error?.data?.detail || "Failed to update the record.",
          open: true,
        });
        throw error;
      }
    },
    [updateDashboardChart, refetchCharts, refetchCotw]
  );

  // Handle changes to the row modes model
  const handleRowModesModelChange = useCallback((newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  }, []);

  // Handle errors by setting alertData
  useEffect(() => {
    if (chartsError) {
      setAlertData({
        status_txt: "error",
        message: chartsError?.data?.detail || "Failed to load charts.",
        open: true,
      });
    }
  }, [chartsError]);

  useEffect(() => {
    if (cotwError) {
      setAlertData({
        status_txt: "error",
        message: cotwError?.data?.detail || "Failed to load Chart of the Week.",
        open: true,
      });
    }
  }, [cotwError]);

  // Define the columns for the DataGridPro
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 90, editable: false },
      // Checkbox Column
      {
        field: "select",
        headerName: "Select",
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const handleChange = (event) => {
            event.stopPropagation(); // Prevents the row from entering edit mode
            handleCheckboxClick(params.id);
          };

          return (
            <Checkbox
              checked={params.row.id === selectedRowId}
              onChange={handleChange}
              aria-label={`Select row ${params.id}`}
              disabled={isSettingCotw}
            />
          );
        },
      },
      {
        field: "title",
        headerName: "Title",
        width: 200,
        editable: true,
        preProcessEditCellProps: (params) => {
          const hasError = params.props.value.trim() === "";
          return { ...params.props, error: hasError };
        },
      },
      {
        field: "subtitle",
        headerName: "Subtitle",
        width: 200,
        editable: true,
      },
      {
        field: "description",
        headerName: "Description",
        width: 300,
        editable: true,
      },
      {
        field: "json_data",
        headerName: "JSON Data",
        width: 250,
        editable: true,
        type: "json",
      },
      {
        field: "chart_url",
        headerName: "Chart URL",
        width: 250,
        editable: true,
        type: "url",
      },
      {
        field: "source_url",
        headerName: "Source URL",
        width: 250,
        editable: true,
        type: "url",
      },
      {
        field: "source",
        headerName: "Source",
        width: 150,
        editable: true,
      },
      {
        field: "created_at",
        headerName: "Created At",
        width: 200,
        editable: false,
      },
      {
        field: "last_updated",
        headerName: "Last Updated",
        width: 200,
        editable: false,
      },

      // Actions Column
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(id)}
                color="inherit"
                key="save"
                aria-label={`Save row ${id}`}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                onClick={handleCancelClick(id)}
                color="inherit"
                key="cancel"
                aria-label={`Cancel edit row ${id}`}
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              key="edit"
              aria-label={`Edit row ${id}`}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
              key="delete"
              aria-label={`Delete row ${id}`}
            />,
          ];
        },
      },
    ],
    [
      rowModesModel,
      handleEditClick,
      handleSaveClick,
      handleDeleteClick,
      handleCancelClick,
      handleCheckboxClick,
      isSettingCotw,
      selectedRowId,
    ]
  );

  // Derive rows directly from chartsData and selectedRowId
  const derivedRows = useMemo(() => {
    if (!chartsData || !Array.isArray(chartsData)) return [];

    return chartsData.map((chart) => ({
      id: chart.id,
      title: chart.title,
      subtitle: chart.subtitle,
      description: chart.description,
      json_data: chart.json_data,
      chart_url: chart.chart_url,
      source_url: chart.source_url,
      source: chart.source,
      created_at: new Date(chart.created_at).toLocaleString(),
      last_updated: new Date(chart.last_updated).toLocaleString(),
      is_chart_of_the_week: chart.id === selectedRowId,
    }));
  }, [chartsData, selectedRowId]);

  return (
    <Box
      sx={{
        height: 600, // Adjust as needed
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        borderColor: 'grey.400',
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: 'background.paper',
        position: 'relative', // To position loading overlays if needed
      }}
    >
      {/* Conditional Rendering for Loading States */}
      {(chartsLoading || cotwLoading) && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* DataGridPro */}
      <DataGridPro
        rows={derivedRows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }} // Ensure you have the new editing API enabled
        disableSelectionOnClick
        autoHeight
      />

      {/* Render AlertMessage */}
      {alertData && (
        <Box sx={{ mt: 2 }}>
          <AlertMessage
            status_txt={alertData.status_txt}
            message={alertData.message}
            open={alertData.open}
            onClose={() => setAlertData(null)}
          />
        </Box>
      )}
    </Box>
  );
}
