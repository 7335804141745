// src/components/DashboardChartForm.jsx

import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useCreateDashboardChartMutation } from '../AdminApi';
import AlertMessage from "../../../components/Ninety-Nine/AlertMessage";

const DashboardChartForm = () => {
  console.log('DashboardChartForm rendered'); // Debugging log
  const projectName = 'ninety-nine';
  const [createDashboardChart] = useCreateDashboardChartMutation();
  const [alertData, setAlertData] = useState(null); // State to manage alert visibility and content

  // State to hold form data
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    description: '',
    json_data: '',
    chart_url: '',
    source_url: '',
    source: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    console.log('Form submit triggered:', e);
    e.preventDefault();
    try {
      const dataPayload = {
        data: {...formData},
        projectName: projectName,
      };
      console.log("Data to send to backend:", dataPayload);
      const response = await createDashboardChart(dataPayload).unwrap();
      console.log('Server response:', response);

      // Trigger success alert
      setAlertData({
        status_txt: 'success',
        message: 'Chart created successfully!',
        open: true,
      });

      // Reset form fields after successful submission
      setFormData({
        title: '',
        subtitle: '',
        description: '',
        json_data: '',
        chart_url: '',
        source_url: '',
        source: '',
      });
    } catch (err) {
      console.error('Failed to create the chart:', err);

      // Trigger error alert
      setAlertData({
        status_txt: 'error',
        message: err.data?.detail || 'An error occurred. Please try again.',
        open: true,
      });
    }
  };

  const handleCloseAlert = () => {
    setAlertData(null); // Reset alert data after it closes
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 500,
        mx: 'auto',
        padding: '20px',
        border: '1px solid',
        borderColor: 'grey.400',
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: 'background.paper',
      }}
    >
      <Typography variant="h6" mb={3} textAlign="center">
        Add Chart
      </Typography>
      <TextField
        fullWidth
        label="Title"
        name="title"
        value={formData.title}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Subtitle"
        name="subtitle"
        value={formData.subtitle}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        multiline
        rows={4}
        required
      />
      <TextField
        fullWidth
        label="JSON Data"
        name="json_data"
        value={formData.json_data}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        fullWidth
        label="Chart URL"
        name="chart_url"
        value={formData.chart_url}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        type="url"
      />
      <TextField
        fullWidth
        label="Source URL"
        name="source_url"
        value={formData.source_url}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
        type="url"
      />
      <TextField
        fullWidth
        label="Source"
        name="source"
        value={formData.source}
        onChange={handleChange}
        variant="outlined"
        margin="normal"
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        fullWidth
      >
        Create Chart
      </Button>

      {/* Render AlertMessage underneath the Button */}
      {alertData && (
        <Box sx={{ mt: 2 }}>
          <AlertMessage
            status_txt={alertData.status_txt}
            message={alertData.message}
            open={alertData.open}
            onClose={handleCloseAlert}
          />
        </Box>
      )}
    </Box>
  );
};

export default DashboardChartForm;

