import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Link
} from "@mui/material";
import EmbedComponent from "../../../components/Ninety-Nine/EmbedComponent";
import NinetyNineHeroSection from "./components/NinetyNineHeroSection";
import CustomBreadcrumbs from "./components/CustomBreadcrumbs";

const NinetyNineOECDWelllbeing = () => {
  const title = "Households' economic well-being: the OECD dashboard"
  const subtext= `Economic growth always gets a lot of attention but when trying to determine
                    how well people are doing it is also interesting to look at indicators
                    that highlight households’ economic activity.`

  return (
    <Fragment>
      <NinetyNineHeroSection title={title} subtext={subtext}/>

        <Box
        sx={{
          width: '90%',
          margin: '0 auto',
           mb: 4
        }}>
          <CustomBreadcrumbs />
      <Link href="https://www.oecd.org/en/data/dashboards/households-economic-well-being-the-oecd-dashboard.html" target="_blank" rel="noopener">
      <Typography variant="body1" component="div" sx={{paddingBottom: 1}} >
        Source: OECD
      </Typography>
    </Link>
        <EmbedComponent
          url="//www.compareyourcountry.org/households-economic-well-being/en/0/290+291+296+298/default/all/ITA+USA+DEU+FRA+GBR"
          width="100%"
          height="80vh"
        />
        </Box>

    </Fragment>
  );
};

export default NinetyNineOECDWelllbeing;
