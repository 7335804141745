import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import EmbedComponent from "../../../components/Ninety-Nine/EmbedComponent";
import { useGetSDMXMutation } from "./NinetyNineApi";
import HighchartsLineChart from '../NinetyNine/components/Highcharts/HighchartsLineChart'
import AlertMessage from "../../../components/Ninety-Nine/AlertMessage";
import {
  useGetChartOfTheWeekQuery,

} from "./NinetyNineApi"; // Ensure these hooks are defined

const NinetyNineChartOfTheWeek = () => {
  const projectName = "ninety-nine";
 // Fetch ChartOfTheWeek
 const {
  data: cotwData,
  error: cotwError,
  isLoading: cotwLoading,
  refetch: refetchCotw,
} = useGetChartOfTheWeekQuery(projectName);

  // State for managing alert messages
  const [alertData, setAlertData] = useState(null);

  // Handle CotW errors
  useEffect(() => {
    if (cotwError) {
      setAlertData({
        status_txt: "error",
        message: cotwError?.data?.detail || "Failed to load Chart of the Week.",
        open: true,
      });
    }
  }, [cotwError]);

 // Determine the URL to embed
 const embedUrl = cotwData && cotwData.chart && cotwData.chart.chart_url
 ? cotwData.chart.chart_url
 : "https://default-embed-url.com"; // Replace with your default URL

  const chartOfTheWeek = [
    "https://ourworldindata.org/explorers/population-and-demography?facet=none&country=USA~JPN~GBR~FRA~FIN~DEU~GRC~ITA~NOR~ESP&Metric=Life+expectancy&Sex=Both+sexes&Age+group=At+birth&Projection+Scenario=None&hideControls=true&tab=chart",
    "https://data-viewer.oecd.org?chartId=8bae25fd-87ee-49a5-bba3-92fb1e1bc9f0",
    "https://ourworldindata.org/grapher/homelessness-rate-point-in-time-count?tab=chart",
    "https://data-viewer.oecd.org/?chartId=66fe3626-a536-4959-9e25-39cb8abfcacc",
    "https://sdmx.oecd.org/public/rest/data/OECD.ELS.HD,DSD_SHA@DF_SHA,1.0/AUS+AUT+CAN+DNK+FIN+FRA+DEU+JPN+ITA+LUX+NLD+NZL+NOR+PRT+CHE+ESP+SWE+USA+GBR.A.EXP_HEALTH.PT_B1GQ._T.._T.._T...?startPeriod=2019&dimensionAtObservation=AllDimensions",
  ];

  const [getSDMX, { data, error, isLoading }] = useGetSDMXMutation();

  useEffect(() => {
    // Calling the mutation with correct parameters
    getSDMX({
      projectName: "ninety-nine",
      url: "https://sdmx.oecd.org/public/rest/data/OECD.ELS.HD,DSD_SHA@DF_SHA,1.0/AUS+AUT+CAN+DNK+FIN+FRA+DEU+JPN+ITA+LUX+NLD+NZL+NOR+PRT+CHE+ESP+SWE+USA+GBR.A.EXP_HEALTH.PT_B1GQ._T.._T.._T...?startPeriod=2019&dimensionAtObservation=AllDimensions"
    });
  }, [getSDMX]);

  console.log("cotwData: ", cotwData);
  console.log("cotwError: ", cotwError);

  return (
    <Fragment>
      <Box
        sx={{
          mb: 2,
          mt: 5,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Chart of the Week
        </Typography>
        <Typography variant="p" gutterBottom>
          We can add some additional commentary here for context if useful.
        </Typography>
      </Box>
      {/* EmbedComponent with dynamic URL */}
      <EmbedComponent url={embedUrl} width="100%" height="40vh" />
      {/* Optionally render HighchartsLineChart if SDMX data is relevant */}
      {/* <HighchartsLineChart data={data} /> */}

      {/* Render AlertMessage for any alerts */}
      {alertData && (
        <Box sx={{ mt: 2 }}>
          <AlertMessage
            status_txt={alertData.status_txt}
            message={alertData.message}
            open={alertData.open}
            onClose={() => setAlertData(null)}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default NinetyNineChartOfTheWeek;
